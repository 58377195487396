import React from 'react';

import SEO from 'src/components/seo';
import { Integrations } from 'src/features/integrations';

export default function IntegrationsPage() {
  return (
    <>
      <SEO title="Settings | Integrations" />

      <Integrations />
    </>
  );
}
