/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { IntegrationsConfiguration } from './IntegrationsConfiguration';

import { getLoading, getConfiguration } from '../state/selectors';
import { useFetchIntegrations } from '../state/hooks';

export const Integrations = () => {
  const isLoading = useSelector(getLoading);
  const data = useSelector(getConfiguration);

  useFetchIntegrations();

  return <LoadingContainer loading={isLoading}>
    {data && <IntegrationsConfiguration data={data} />}
  </LoadingContainer>;
};
