/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, Grid, Divider } from '@material-ui/core';
import { IntegrationsList } from './IntegrationsList';

import Paper from 'src/components/paper';
import Tab from 'src/components/tab';

const useStyles = makeStyles(theme => ({
  paperContainer: {
    display: 'flex',
    width: '100%',
  },
  textWrapper: {
    flexGrow: 1,
    padding: theme.spacing(0, 2),
  },
  iconWrapper: {
    minWidth: 80,
  },
  iconWrapperTable: {
    height: 25,
    '& > svg': {
      height: 25,
      marginRight: 14,
    },
  },
  integrationTypeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  infoLine: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
    height: 60,
    overflowWrap: 'break-word',
    marginTop: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
      height: 'auto',
    },
  },
  hotglueWrap: {
    '& .hg-flows-container': {
      fontWeight: 700,
      fontSize: '16px',
    },
    '& .hg-flow-title': {
      fontWeight: 700,
      fontSize: '16px',
    },
    '& .hg-flow-subtitle': {
      fontWeight: 700,
      fontSize: '16px',
    },
    '& .hg-connector-name': {
      fontWeight: 400,
      fontSize: '16px',
    },
  },
}));

export const IntegrationsConfiguration = ({ data }) => {
  const classes = useStyles();

  const [isExpanded, setExpandToggle] = useState(false);

  const handleExpand = () => {
    setExpandToggle(!isExpanded);
  };

  const { accountId, token, username, url } = data;

  return (
    <>
      <Paper>
        <Typography variant="h6" paragraph style={{ marginBottom: '32px' }}>
          Integrations
        </Typography>

        <IntegrationsList data={data} />
      </Paper>

      <div style={{ marginBottom: '40px' }}>
        <Tab
          title="Configuration Info"
          tooltipedText="Use this info to manually connect your lead source(s)"
          onClick={handleExpand}
          expanded={isExpanded}
          drag
        >
          <Grid container direction="column">
            <Grid container className={classes.infoLine}>
              <Grid item xs={12} md={4}>
                <Typography>
                  <b>Account ID</b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography>{accountId}</Typography>
              </Grid>
            </Grid>

            <Divider />

            <Grid container className={classes.infoLine}>
              <Grid item xs={12} md={4}>
                <Typography>
                  <b>Username</b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography>{username}</Typography>
              </Grid>
            </Grid>

            <Divider />

            <Grid container className={classes.infoLine}>
              <Grid item xs={12} md={4}>
                <Typography>
                  <b>API key</b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography>{token}</Typography>
              </Grid>
            </Grid>

            <Divider />

            <Grid container className={classes.infoLine}>
              <Grid item xs={12} md={4}>
                <Typography>
                  <b>URL</b>
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography>{url}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Tab>
      </div>
    </>
  );
};
