import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from './slice';

export const useFetchIntegrations = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getConfiguration());
  }, [dispatch]);
};
